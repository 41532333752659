.containerTrayectoria h3 {
  text-align: center;
  font-family: 'LeagueSpartan-Bold' !important;
  font-size: 2rem;
  margin-top: 100px;
}

.containerTrayectoria .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
}

.trayectoriaContentDiv { font-family: Georgia, 'Times New Roman', Times, serif; }
.trayectoriaContentDiv h4 { text-align: center; }
.trayectoriaContentDiv h5 { text-align: center; }

.avatarPic {
  background-image: url('../../images/cv/pic/pic.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 50%;
  border: 1px solid #14618c;
  -webkit-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
}

.iconCV {
  color: #e6e6e6;
  text-align: center;
  background-color: #1e2124;
  padding: 6px 10px;
  font-size: 1.2rem;
  margin: 0 auto;
  width: 42px;
  border-radius: 50%;
}

.cv_title {
  text-align: center;
  font-family: 'Roboto';
  font-size: 1.1rem;
  margin-top: 10px;
}

.cv_p { font-family: 'Roboto-Light' !important; text-align: center; font-size: .9em;}
.cv_data-item { font-family: 'Roboto-Light' !important; }
.cv_data-item ul { margin-bottom: 40px; }
.cv_data-item ul span:first-child { font-weight: bold; font-size: 1.2rem; }
.cv_data-item li { margin-bottom: 10px; list-style-type: square; }
.cv_data-item .locale { font-weight: bold; font-size: 1.1rem; }
.cv_data-item .dateInOut { font-size: .9rem; }
.cv_data-item .adress { font-size: .8rem; color: #838383; margin-top: 5px; }
.cv_data-item .locale_title {
  font-style: italic;
  color: #14618c;
  margin-top: 5px;
  font-size: .9rem;
}

.cv_skills { 
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.cv_skills img { width: 15%; }

.containerDownloadCV {
  background-color: #0e1a40;
  height: 350px;  
  text-align: center;
  color: #e6e6e6;
}

.containerDownloadCV h3 {
  font-family: 'LeagueSpartan-Bold' !important;
  font-size: 2.5rem;
  padding-top: 80px;
  padding-bottom: 20px;
}

.containerDownloadCV hr { border-color: rgba(230, 230, 230, 0.3); margin-bottom: 40px; }

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .containerTrayectoria .row { width: 95%; margin: 0 auto; }
  .cv_skills { padding-top: 20px;}
  .cv_skills img { width: 35%; }
  .containerDownloadCV h3 { font-size: calc(.5rem + 4vw); }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .containerTrayectoria .row { width: 95%; margin: 0 auto; }
  .cv_skills { padding-top: 20px;}
  .cv_skills img { width: 35%; }
  .containerDownloadCV h3 { font-size: calc(.5rem + 4vw); }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .containerTrayectoria .row { width: 95%; margin: 0 auto; }
  .cv_skills { padding-top: 20px;}
  .cv_skills img { width: 40%; }
  .containerDownloadCV h3 { font-size: calc(.5rem + 4vw); }
}
/* notebook promedio */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .hide-responsive { display: block; }
}
/* escritorio medio */
@media only screen and (min-width: 1361px) and (max-width: 1440px) {
  .hide-responsive { display: block; }
}
/* escritorio grande */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .hide-responsive { display: block; }
}
