.navegation {
  color: #f0f0f0;
  padding: 10px 10%;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(74, 74, 74, .9);
}

.nav-logo {
  width: 50px;
  height: 40px;
  animation: latir infinite 3.5s linear;
}

.nav-group {height: 40px;}
.nav-group ul {list-style-type: none;}
.nav-group ul li {
  font-family: 'LeagueSpartan-Bold';
  display: inline-block;
  padding: 10px 15px;
  margin-left: 10px;
}

.nav-group ul li > .cool-link {
  display: inline-block;
  color: #e6e6e6;
  text-decoration: none;
}

.nav-group ul li > .cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #FFC107;
  transition: width .3s;
    -webkit-transition: width .3s;
    -ms-transition: width .3s;
}

.nav-group ul li > .cool-link:hover::after {
  width: 100%;
}

.btn-responsive {
  display: none;
  border: 1px solid #FFC107;
  border-radius: 8px;
  color: #FFC107;
  font-size: 2rem;
  position: fixed;
  z-index: 999;
  padding: 3px 13px;
  top: 10px;
  right: 10px;
  -webkit-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.nav-responsive {
  background-color: #e6e6e6;
  position: fixed;
  width: 280px;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: -300px;
  border-right: 1px solid #b9b9b9;
  transition: left .2s linear;
  overflow: scroll;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.nav-responsive ul { 
  list-style-type: none; 
  margin-left: -40px;
}
.nav-responsive ul li {
  border-bottom: 1px solid #b9b9b9;
  font-family: 'LeagueSpartan-Bold';
  font-size: 1.1rem;
}
.nav-responsive ul li:first-child {text-align: center;padding: 10px;}

.nav-responsive ul li a {
  color: #14618c;
  display: block;
  padding: 20px;
  transition: background .3s linear;
}

.nav-responsive ul li a:hover {
  color: #e6e6e6;
  display: block;
  padding: 20px;
  text-decoration: none;
  background-color: #14618c;
}

@media only screen and (max-width: 768px) {
  .btn-responsive {display: block; z-index: 999999;}
  .navegation {display: none !important;}
  ::-webkit-scrollbar {display: none;}
}
@media only screen and (min-width: 769px) {
  .btn-responsive {display: none;}
  .navegation {display: flex;}
}