/* Nunito */
@font-face {font-family: 'Nunito-Light'; src: url(../../../fonts/nunito/Nunito-Light.ttf);}
@font-face {font-family: 'Nunito-Regular'; src: url(../../../fonts/nunito/Nunito-Regular.ttf);}
@font-face {font-family: 'Nunito-Bold'; src: url(../../../fonts/nunito/Nunito-Bold.ttf);}

/* Roboto */
@font-face {font-family: 'Roboto-Light'; src: url(../../../fonts/roboto/Roboto-Light.ttf);}
@font-face {font-family: 'Roboto-Regular'; src: url(../../../fonts/roboto/Roboto-Regular.ttf);}
@font-face {font-family: 'Roboto-Bold'; src: url(../../../fonts/roboto/Roboto-Bold.ttf);}

/* League Spartan */
@font-face {font-family: 'LeagueSpartan-Bold'; src: url(../../../fonts/LeagueSpartan-Bold.otf);}

/* Fredoka One */
@font-face {font-family: 'FredokaOne-Regular'; src: url(../../../fonts/FredokaOne-Regular.ttf);}

/* Comfortaa */
@font-face {font-family: 'Comfortaa-Regular'; src: url(../../../fonts/Comfortaa/Comfortaa-Regular.ttf);}