.contenedor-footer {
  background-color: #14618c;
  color: #f0f0f0;
  height: 100vh; 
}

.footer_group {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: relative;
}

/* Contact Footer */
.contact-footer { padding: 50px 0; }

hr {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  width: 40%;
  margin-bottom: 40px;
}

.subheader_h2 { color: #f0f0f0; padding-bottom: 40px; }

.social-group {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
}

.social-circle {
  color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  padding: 10px 15px;
  margin: 0 15px;
}

.social-circle:hover { color: #ddd; }

.logo-footer {
  margin: 40px auto;
  font-size: 1em;
  width: 100px;
  height: 70px;
  animation: latir infinite 3.5s linear;
}

.info-footer { margin: 20px; text-align: center; }

/* RESPONSIVE */
/* Samsung Galaxy S7 EDGE */
@media only screen and (min-width: 640px) and (max-width: 719px) {
  .contenedor-footer { height: 600px; max-height: 720px; }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .contenedor-footer { height: 600px; max-height: 720px; }
}
