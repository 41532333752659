.contentNavbarAllProject { height: 80px; border-bottom: 1px solid #dddddd; }
.containerAllProject h3 {
  text-align: center;
  font-family: 'LeagueSpartan-Bold';
  font-size: 2rem;
  margin-top: 60px;
}
.containerAllProject .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
}
.contentAllProject {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.contentAllProject .oneProject {
  width: 300px;
  height: 300px;
  margin-bottom: 40px;
  -webkit-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
}
.contentAllProject .oneProject a { display: block; position: relative; overflow: hidden; }
.contentAllProject .oneProject div:first-child { width: 300px; height: 300px; }
.contentAllProject .oneProject div h4 {
  color: #e6e6e6;
  font-family: 'LeagueSpartan-Bold';
  font-size: 1.5rem;
  width: 100%;
  padding-top: 110px;
  margin: 20px;
  text-align: center;
}
.contentAllProject .oneProject div:last-child {
  position: absolute;
  background-color: #14618c;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  transform: translateY(100%);
}
.contentAllProject .oneProject a div { transition: transform 0.2s ease-out; }
.contentAllProject .oneProject a:hover div:first-child { transform: translateY(-100%); }
.contentAllProject .oneProject a:hover div:last-child { transform: none; }

.trans {
  -webkit-transition: background 1s, border-radius 1s;
  transition: background 1s, border-radius 1s;
}

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .containerAllProject h3 { font-size: 1.5rem; }
  .contentAllProject .oneProject { border-radius: 10px; }
  .contentAllProject .oneProject div { border: 1px solid #868686; border-radius: 3%;}
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .containerAllProject h3 { font-size: 1.5rem; }
  .contentAllProject .oneProject { border-radius: 10px; }
  .contentAllProject .oneProject div { border: 1px solid #868686; border-radius: 3%;}
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .containerAllProject h3 { font-size: 1.5rem; }
  .contentAllProject .oneProject { border-radius: 10px; }
  .contentAllProject .oneProject div { border: 1px solid #868686; border-radius: 3%;}
}
