.containerErrors { background-color: #e6e6e6; text-align: center; }
.containerErrors h1 {
  font-family: 'LeagueSpartan-Bold';
  font-size: 2.5rem;
  color: #4A4A4A;
  text-align: center;
  margin-top: 100px;
}
.containerErrors h4 {
  font-family: 'LeagueSpartan-Bold';
  font-size: 1.2rem;
  color: #4A4A4A;
  text-align: center;
  margin-top: 40px;
}
.containerErrors .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
}
.containerErrors .imgError { margin: 60px auto; margin-left: -15px; width: 50%; }