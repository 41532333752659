.headView_group { width: 100%; padding-top: 350px; text-align: center; }
.proyectoView { height: auto; }
.proyectoViewImg { background-size: auto; }

.proyectoView h1 {
  font-family: 'LeagueSpartan-Bold';
  font-size: 3rem;
  color: #FFC107;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
}

.proyectoView h3 {
  font-family: 'Roboto-Regular';
  font-size: 2rem;
  color: #f0f0f0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
}

.tarjetasProyectos { min-height: 250px; padding-bottom: 10px; }

.card {
  height: 120px;
  text-align: center;
  counter-reset: #4a4a4a;
  -webkit-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
}
.card-header { color: #14618c; }

/* Sobre el proyecto */
.sobreElProyecto {
  background-color: #0E1A40;
  text-align: center;
  padding: 40px 0;
}
.sobreElProyecto .subheader_h2 {
  font-family: 'FredokaOne-Regular';
  font-size: 3rem;
  padding-bottom: 10px;
  color: #e6e6e6;
}
.sobreElProyecto p {
  width: 50%;
  margin: 0 auto;
  color: #e6e6e6;
  font-size: 1.3rem;
}

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .headView_group {
    width: 100%;
    padding: 100px 30px;
    text-align: center;
    background-color: rgba(50, 50, 51, .8);
  }
  .proyectoView h1 { font-size: 2rem; }
  .proyectoView h3 { font-size: 1.5rem; }
  .proyectoViewImg { background: none !important; max-height: auto; }

  .tarjetasProyectos { min-height: 250px; padding-bottom: 10px; margin-bottom: 30px; }

  .sobreElProyecto .subheader_h2 { font-size: 2rem; }
  .sobreElProyecto p { font-size: 1.3rem; width: 85%; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .headView_group {
    width: 100%;
    padding: 100px 30px;
    text-align: center;
    background-color: rgba(50, 50, 51, .8);
  }
  .proyectoView h1 { font-size: 2rem; }
  .proyectoView h3 { font-size: 1.5rem; }
  .proyectoViewImg { background: none !important; max-height: auto; }

  .tarjetasProyectos { min-height: 250px; padding-bottom: 10px; margin-bottom: 30px; }

  .sobreElProyecto .subheader_h2 { font-size: 2rem; }
  .sobreElProyecto p { font-size: 1.3rem; width: 85%; }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .headView_group {
    width: 100%;
    padding: 180px 30px 0 30px;
    text-align: center;
  }
  .proyectoView h1 { font-size: 2rem; }
  .proyectoView h3 { font-size: 1.5rem; }
  .proyectoViewImg { max-height: auto; }

  .tarjetasProyectos { min-height: 250px; padding-bottom: 20px; margin-bottom: 0;}

  .sobreElProyecto .subheader_h2 { font-size: 2rem; }
  .sobreElProyecto p { font-size: 1.3rem; width: 85%; }
}
/* notebook promedio */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .headView_group { padding: 180px 30px 0 30px; }
  .tarjetasProyectos { min-height: 250px; padding-bottom: 20px; margin-bottom: 0;}
  .proyectoViewImg { background-size: contain !important; }
}
/* escritorio medio */
@media only screen and (min-width: 1367px) and (max-width: 1440px) {
  .headView_group { padding: 180px 30px 0 30px; }
  .tarjetasProyectos { min-height: 250px; padding-bottom: 20px; margin-bottom: 0;}
  .proyectoViewImg { background-size: contain !important; }
}
/* escritorio grande */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .headView_group { padding: 180px 30px 0 30px; }
  .tarjetasProyectos { min-height: 250px; padding-bottom: 20px; margin-bottom: 0;}
  .proyectoViewImg { background-size: contain !important; }
}
