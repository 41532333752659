.containerBranding .row { border-top: 1px dashed #acacac; width: 95%; margin: 0 auto; }
.containerBranding h4 { text-align: center; font-family: 'LeagueSpartan-Bold'; font-size: 1.5rem; }
.containerBranding .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
  padding-bottom: 40px;
}
.branding__title { font-family: 'Fredoka One', cursive; font-size: 2.5rem; margin-bottom: 40px; }
.branding__info { font-size: 1.3rem; font-family: 'Roboto-Light'; }
.branding___img { width: 100%; padding: 50px; }
.branding__tipo_title { font-size: 2rem; margin: 40px; }
.branding__colors .circles {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 20px 2px rgba(0,0,0,0.25);
}
.branding__colors span {
  text-align: center;
  width: 100px;
  float: left;
  margin: 20px auto;
}
.show-responsive { display: none; }

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .containerBranding .row { margin: 20px auto; }
  .branding__title { padding-top: 20px; text-align: center;}
  .branding__info { text-align: center; }
  .vertical-line {border: none;}
  .branding__tipo_title { padding-top: 40px;}
  .branding__colors { width: 100%; padding: 20px; margin: 10px auto; }
  .branding__colors > div { padding: 30%; margin: 0 auto; }
  .branding__colors > span { width: 100%; font-size: 1.5rem; }
  .border-hide-responsive { border: 0 !important; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .containerBranding .row { margin: 20px auto; }
  .branding__title { padding-top: 20px; text-align: center;}
  .branding__info { text-align: center; }
  .vertical-line {border: none;}
  .branding__tipo_title { padding-top: 40px;}
  .branding__colors { width: 100%; padding: 20px; margin: 10px auto; }
  .branding__colors > div { padding: 30%; margin: 0 auto; }
  .branding__colors > span { width: 100%; font-size: 1.5rem; }
  .border-hide-responsive { border: 0 !important; }
}
/* Samsung Galaxy S7 EDGE */
@media only screen and (min-width: 640px) and (max-width: 719px) {
  .containerBranding .row { margin: 20px auto; }
  .branding__title { padding-top: 20px; text-align: center;}
  .branding__info { text-align: center; }
  .vertical-line {border: none;}
  .branding__tipo_title { padding-top: 40px;}
  .branding__colors { width: 25%; padding: 20px; margin: 10px auto; }
  .branding__colors > div { padding: 30%; margin: 20px auto; }
  .branding__colors > span { width: 100%; font-size: 1rem; }
  .border-hide-responsive { border: 0 !important; }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .containerBranding .row { margin: 20px auto; }
  .branding__title { padding-top: 20px; text-align: center;}
  .branding__info { text-align: center; }
  .vertical-line {border: none;}
  .branding__tipo_title { padding-top: 40px;}
  .branding__colors { width: 25%; padding: 20px; margin: 10px auto; }
  .branding__colors > div { padding: 30%; margin: 20px auto; }
  .branding__colors > span { width: 100%; font-size: 1rem; }
  .border-hide-responsive { border: 0 !important; }
}
/* notebook promedio */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .branding__colors { width: 25%; padding: 20px; margin: 20px auto; }
  .branding__colors > div { padding: 30%; margin: 20px auto; }
  .branding__colors > span { width: 100%; font-size: 1rem; }
}
/* escritorio medio */
@media only screen and (min-width: 1367px) and (max-width: 1440px) {
  .branding__colors { width: 25%; padding: 20px; margin: 20px auto; }
  .branding__colors > div { padding: 30%; margin: 20px auto; }
  .branding__colors > span { width: 100%; font-size: 1rem; }
}
/* escritorio grande */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .branding__colors { width: 25%; padding: 20px; margin: 20px auto; }
  .branding__colors > div { padding: 30%; margin: 20px auto; }
  .branding__colors > span { width: 100%; font-size: 1rem; }
}
