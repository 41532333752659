.contenedor-header {
  height: 100vh;
  background: #1e2124 fixed;
  background: -webkit-linear-gradient(to top, #0e1a40, #000) fixed; 
  background: linear-gradient(to top, #0e1a40, #000) fixed; 
}

#tsparticles { height: 100vh; z-index: 99 !important; }

.info-header {
  position: absolute;
  top: 40%;
  z-index: 999;
  color: #f0f0f0;
  padding: 20px 10%;
}

.avatarModal {
  width: 35%;
  height: 180px;
  margin: 0 auto;
  margin-bottom: 40px;
  background-image: url('../../images/svg/avatar/avatar_new_active.svg');
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s ease;
}
.avatarModal:hover {
  background-image: url('../../images/svg/avatar/avatar_new_hover.svg');
  transform: scale(1.1);
}

.cv_p { font-family: 'Roboto-Light' !important; text-align: center; font-size: .9em; }
.info-header h1 { font-family: 'LeagueSpartan-Bold' !important; font-size: 6rem; }
.info-header h1 a { color: #e6e6e6; text-decoration: none; }
.info-header h1 a:hover {
  opacity: .5;
  text-decoration: none;
  transition: opacity .5s linear;
    -webkit-transition: opacity .5s linear;
    -ms-transition: copacity .5s linear;
}

.info-header h3 {
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 1.5rem;
}

.header__arrowDown {
  text-align: center;
  font-size: 2.5rem;
  color: #e6e6e6;
  position: absolute;
  z-index: 99;
  top: 80%;
  width: 100%;
  padding: 20px 10%;
  animation: bote infinite 2s linear; 
}

.header__arrowDown span { font-size: 1rem; }
.header__arrowDown a { color: #e6e6e6; }
.header__arrowDown a:hover { color: #e6e6e6; transform: translateY(50%); }

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .info-header {
    top: 20% !important;
    width: 100%;
    text-align: center;    
  }
  .info-header h1 { font-size: calc(1rem + 8vw); }
  .info-header h3 { font-size: calc(1rem + 1vw); }
  .info-header button {
    margin: 20px;
    width: 80%;
    position: relative;
    z-index: 9999;
  }
  .header__arrowDown { top: 75%; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 639px) {
  .info-header {
    top: 35% !important;
    width: 100%;
    text-align: center;    
  }
  .info-header h1 { font-size: calc(1rem + 8vw); }
  .info-header h3 { font-size: calc(1rem + 1vw); }
  .info-header button {
    margin: 20px;
    width: 80%;
    position: relative;
    z-index: 9999;
  }
  .header__arrowDown { top: 75%; }
}
/* Samsung Galaxy S7 EDGE */
@media only screen and (min-width: 640px) and (max-width: 719px) {
  .info-header {
    top: 0 !important;
    width: 100%;
    text-align: center;    
  }
  .info-header h1 { font-size: calc(1rem + 8vw); }
  .info-header h3 { font-size: calc(1rem + 1vw); }
  .info-header button {
    margin: 10px;
    width: 80%;
    position: relative;
    z-index: 9999;
  }
  .header__arrowDown { top: 75%; }
}

/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .info-header {
    top: 35% !important;
    width: 100%;
    text-align: center;    
  }
  .info-header h1 { font-size: calc(1rem + 10vw); }
  .info-header h3 { font-size: calc(1rem + 3vw); }
  .info-header button {
    margin: 20px;
    padding: 20px;
    font-size: 1.2rem;
    width: 50%;
    position: relative;
    z-index: 9999;
  }
  .header__arrowDown { top: 75%; }
}
