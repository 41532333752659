/* Título de Introducción (Principal) */
.proyectos_h2 {
  font-family: 'LeagueSpartan-Bold';
  font-size: 2.5rem;
  text-align: center;
  color: #1e2124;
  padding-top: 60px;
}
.proyectos .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
}
/* Proyectos Intro (Principal) */
.proyecto-intro {
  margin: 100px auto;
  width: 900px;
  -webkit-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  position: relative;
}
.proyecto-intro:hover > .proyecto-intro-contenido {
  transform: translateY(0%);
  transition: all .2s linear;
  opacity: 100;
  visibility:visible;
}
.proyecto-intro:hover > .num-proyecto {
  transform: translateY(-40%);
  transition: all .2s linear;
  color: #ffc107;
}
.num-proyecto {
  position: absolute;
  z-index: 999;
  margin-top: 0;
  margin-left: 660px;
  text-align: right;
  width: 200px;
  color: #f0f0f0;
  font-family: 'LeagueSpartan-Bold';
  font-size: 9rem;   
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
  transform: translateY(0%);
}
.proyecto-intro-contenido {
  background-color: rgba(30, 33, 36, .9);
  visibility: hidden;
  height: 520px;
  width: 100%;
  padding: 0 100px;
  padding-top: 350px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(100%);
}
.proyecto-intro-contenido h3 {
  color: #ffc107;
  font-family: 'LeagueSpartan-Bold';
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
}
.proyecto-intro-contenido p {
  color: #f0f0f0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
  font-size: 1.3em;
}
/* Más Projectos (Principal) */
.moreProjects_group {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: relative;
}
.moreProjects {
  height: 100vh;
  background: url('../../images/more-project.jpg') no-repeat;
  background-size: cover;
  width: 100%;
}
.moreProjects h2 {
  font-family: 'LeagueSpartan-Bold';
  font-size: 2.5rem;
  text-align: center;
  color: #f0f0f0;
  padding-top: 60px;
}
.moreProjects_avatar {
  background-color: #f0f0f0;
  background-image: url('../../images/svg/avatar/avatar_new(SinBG).svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size:290px;
  border-radius: 100%;
  height: 250px;
  width: 250px;
  margin: 60px auto;
  animation: latir infinite 3.5s linear;
}
.moreProjects_button {display: flex; justify-content: center;}
.moreProjects_button:hover {text-decoration: none;}
/* Experimentos / Laboratorio */
.lab {text-align: center; margin-bottom: 60px;}
.lab .icon_lab {
  background: url('../../images/svg/lab/lab.svg') no-repeat;
  width: 60px;
  height: 60px;
  margin: 40px auto;
}
.lab h3 {margin-bottom: 60px;}
.lab .card {
  height: auto;
  -webkit-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
}
.lab .head_code {
  font-size: .9rem;
  color: #14618c;
  margin-top: 5px;
}

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .proyecto-intro {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
    margin-top: 60px !important;
    text-align: center;
  }
  .num-proyecto { font-size: calc(1rem + 17vw); margin-left: 135px; }
  .proyecto-intro img { width: 95%; }
  .proyecto-intro-contenido {
    height: 200px;
    width: 100%;
    padding: 20px;
    padding-top: 50px;
  }
  .proyecto-intro-contenido h3 { font-size: 1rem; }
  .proyecto-intro-contenido p { font-size: .9em; }
  .proyecto-intro-contenido button { padding: 5px 10px; font-size: .8rem; }

  .moreProjects h2 { font-size: 1.8rem; }
  .moreProjects button { margin-bottom: 60px; } 

  .lab .proyectos_h2 { font-size: 1.8rem; }
  .lab h3 { font-size: 1rem; }
  .lab .card { width: 100%; margin-bottom: 20px; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 639px) {
  .proyecto-intro {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
    margin-top: 60px !important;
    text-align: center;
  }
  .num-proyecto { font-size: calc(1rem + 17vw); margin-left: 155px; }
  .proyecto-intro img { width: 95%; }
  .proyecto-intro-contenido {
    height: 206px;
    width: 100%;
    padding: 20px;
    padding-top: 50px;
  }
  .proyecto-intro-contenido h3 { font-size: 1.2rem; }
  .proyecto-intro-contenido p { font-size: 1em; }
  .proyecto-intro-contenido button { padding: 5px 10px; font-size: .8rem; }

  .moreProjects h2 { font-size: 1.8rem; }
  .moreProjects button { margin-bottom: 60px; } 

  .lab .proyectos_h2 { font-size: 1.8rem; }
  .lab h3 { font-size: 1rem; }
  .lab .card { width: 100%; margin-bottom: 20px; }
}
/* Samsung Galaxy S7 EDGE */
@media only screen and (min-width: 640px) and (max-width: 719px) {
  .proyecto-intro {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
    margin-top: 60px !important;
    margin-bottom: 60px;
    text-align: center;
  }
  .num-proyecto { font-size: calc(1rem + 17vw); margin-left: 320px; }
  .proyecto-intro img { width: 100%; }
  .proyecto-intro-contenido {
    height: 312px;
    width: 100%;
    padding: 20px;
    padding-top: 120px;
  }
  .proyecto-intro-contenido h3 { font-size: 1.2rem; }
  .proyecto-intro-contenido p { font-size: 1em; }
  .proyecto-intro-contenido button { padding: 5px 10px; font-size: .8rem; }

  .moreProjects { height: 550px;}
  .moreProjects h2 { font-size: 2rem; }
  .moreProjects button { margin-bottom: 60px; } 

  .lab .proyectos_h2 { font-size: 3rem; }
  .lab h3 { font-size: 2rem; }
  .lab .card { width: 100%; margin-bottom: 20px; }
}

/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .proyecto-intro {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
    margin-top: 60px !important;
    margin-bottom: 60px;
    text-align: center;
  }
  .num-proyecto { font-size: calc(1rem + 17vw); margin-left: 320px; }
  .proyecto-intro img { width: 100%; }
  .proyecto-intro-contenido {
    height: 312px;
    width: 100%;
    padding: 20px;
    padding-top: 120px;
  }
  .proyecto-intro-contenido h3 { font-size: 1.2rem; }
  .proyecto-intro-contenido p { font-size: 1em; }
  .proyecto-intro-contenido button { padding: 5px 10px; font-size: .8rem; }

  .moreProjects h2 { font-size: 4rem; }
  .moreProjects button { margin-bottom: 60px; } 

  .lab .proyectos_h2 { font-size: 3rem; }
  .lab h3 { font-size: 2rem; }
  .lab .card { width: 100%; margin-bottom: 20px; }
}
