.containerDesign { padding-top: 60px; }
.containerDesign h4 { text-align: center; font-family: 'LeagueSpartan-Bold'; font-size: 1.5rem; }
.containerDesign .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
}
.containerDesign img { margin: 0 auto; }

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
/* notebook promedio */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
/* escritorio medio */
@media only screen and (min-width: 1361px) and (max-width: 1440px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
/* escritorio grande */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .containerDesign { padding-top: 20px; }
  .containerDesign .fa-sort-down { margin-bottom: 40px; }
}
