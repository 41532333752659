* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #1e2124;
  background: #E6E6E6;
}

@font-face {
  font-family: LeagueSpartan-Bold;
  src: local("LeagueSpartan-Bold"),
  url(./fonts/LeagueSpartan-Bold.otf);
}

.subheader_h2 {
  font-family: 'LeagueSpartan-Bold';
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.anchorTop {
  background-color: #14618c;
  color: #e6e6e6;
  position: fixed;
  z-index: 9999999999;
  padding: 15px 22px;
  border-radius: 50%;
  margin: 0 40px 40px 0;
  border: 2px solid #e6e6e6;
  bottom: 0;
  right: 0;
}

.hidden {
  display: none;
}

.visible {
  display: block;
  animation-name: visible;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.vertical-line-relative {
  height: 40px;
  width: 1px;
  margin: 20px auto;
  border-left: 1px dashed #acacac;
}

.horizontal-line-relative {
  height: 1px;
  width: 100%;
  margin: 20px auto;
  border-bottom: 1px dashed #acacac;
}

.vertical-line {
  border-left: 1px dashed #acacac;
}

.border-hide-responsive {
  padding: 20px;
}

.hide-responsive {
  display: none;
}

/* Animaciones */
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes latir {
  0% {transform: scale(1,1);}
  50% {transform: scale(1.02,1.02);}
  55% {transform: scale(0.9,0.9);}
  100% {transform: scale(1,1);}
}

@keyframes bote {
  20%, 50%, 80% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  65% {transform: translateY(-15px);}
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%; /* Asegura que la tarjeta se adapte a su contenedor */
  max-width: 400px; /* Puedes ajustar este valor si es necesario */
  margin: auto; /* Centrar la tarjeta horizontalmente */
  height: 430px !important;
}

.card-header {
  padding: 16px;
  background-color: #f5f5f5;
  text-align: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.profile-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.profile-description {
  text-align: center;
  color: #777;
  font-size: 1rem;
}