.navView___hr { 
  border: 0;
  border-bottom: 1px solid rgba(75, 75, 75, .2) !important;
  width: 100%;
}
.enlaces-proyects {
  display: block;
  padding: 40px;
  margin-top: -15px;
  font-family: 'LeagueSpartan-Bold';
  font-size: 1.2rem;
  color: #1e2124;
  transition: color .3s linear;
}
.enlaces-proyects:hover { color: #14618c; text-decoration: none; }
.enlace-back { text-align: left; }
.enlace-next { text-align: right; }

/* RESPONSIVE */
/* movil pequeño */
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1rem; }
}
/* movil mediano */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1rem; }
}
/* movil grande */
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1.3rem; }
}
/* notebook promedio */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1.3rem; }
}
/* escritorio medio */
@media only screen and (min-width: 1361px) and (max-width: 1440px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1.3rem; }
}
/* escritorio grande */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .enlaces-proyects { width: 100%; padding: 20px; font-size: 1.3rem; }
}
