.containerContact { background-color: #e6e6e6; }
.containerContact h3 {
  text-align: center;
  font-family: 'LeagueSpartan-Bold';
  font-size: 2rem;
  margin-top: 100px;
}
.containerContact .fa-sort-down {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #14618c;
  margin-bottom: 40px;
}

.mail_success { padding-bottom: 60px; }
.mail_success a:hover { text-decoration: none; }
.mail_success img { width: 50%; }
.mail_success h4 { font-size: 36px; padding: 40px 0 10px 0; }
